






import { Vue, Component } from "vue-property-decorator";

@Component
export default class About extends Vue {
  mounted() {
    this.$store.dispatch("loadPage", 199);
  }

  get page() {
    return this.$store.state.page;
  }
}
